import React from "react";
// import logo from "../../images/logo.png";
import logo from "../../images/logo1.png";

export default function Header() {
  return (
    <>
      <div className="bg-white 2xl:px-[80px] xl:px-[60px] md:px-[50px] lg:px-[70px] sm:px-[30px] px-[20px] my-5 flex justify-between items-center">
        <div className="flex items-center">
          <img src={logo} alt="logo" className="h-14" />
          <h1 className="font-extrabold text-xl hidden md:flex md:ps-5">
            Renko
          </h1>
        </div>
        <div className="flex gap-4">
          <button
            className="rounded border-gray-400 border-2 bg-white py-1.5 px-5 w-32 hidden md:flex" //hover:bg-slate-950 hover:border-slate-950 hover:text-white
            type="button"
          >
            Start Free
          </button>
          <button
            className="rounded border-black border-2 bg-black text-white py-1.5 px-5 w-32" //hover:bg-white hover:text-black hover:border-gray-400
            type="button"
          >
            Login
          </button>
        </div>
      </div>
    </>
  );
}
