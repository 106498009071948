import React from "react";
import "../../css/style.css";
import background from "../../images/bg.png";
import appstore from "../../images/appstore.png";
import playstore from "../../images/playstore.png";
import arrow from "../../images/arrow.png";
import mainimg from "../../images/mainPage.png";
import services from "../../images/services.png";
import jobs from "../../images/jobs.png";
import properties from "../../images/property.png";
import task from "../../images/task1.png";
import check from "../../images/check1.png";
import sec1 from "../../images/sec1.png";
import sec2 from "../../images/sec2.png";
import sec3 from "../../images/sec3.png";
import sec4 from "../../images/sec4.png";
import sec5 from "../../images/sec5.png";
import sec6 from "../../images/sec6.png";
import sec7 from "../../images/sec7.png";
import save from "../../images/save.png";
import plus from "../../images/plus.png";
import minus from "../../images/minus.png";
import { useState } from "react";
import { Disclosure } from "@headlessui/react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

export default function LandingPage() {
  const [activeTab, setActiveTab] = useState("Monthly");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const settings = {
    speed: 500,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      { breakpoint: 1280, settings: { slidesToShow: 3, slidesToScroll: 1 } },
      { breakpoint: 1024, settings: { slidesToShow: 2, slidesToScroll: 1 } },
      { breakpoint: 768, settings: { slidesToShow: 1, slidesToScroll: 1 } },
      { breakpoint: 576, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    ],
  };
  const [openIndex, setOpenIndex] = useState(null); // State to track the currently open accordion

  const handleToggle = (index) => {
    // If the clicked accordion is already open, close it; otherwise, open it
    setOpenIndex(openIndex === index ? null : index);
  };
  const faqData = [
    {
      question: "Q. What is Renko?",
      answer:
        "Renko is a convenient app that brings together all your service, property, and job needs on one platform.",
    },
    {
      question: "How does Renko make finding properties easier?",
      answer:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam harum autem temporibus blanditiis ipsa in, asperiores eos quam maxime commodi molestias facilis fugit illo cupiditate eveniet quidem culpa! Nisi, voluptatibus?",
    },
    {
      question: "Are service providers on Renko trustworthy?",
      answer:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam harum autem temporibus blanditiis ipsa in, asperiores eos quam maxime commodi",
    },
    {
      question: "Can other info be added to an invoice?",
      answer:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam harum autem temporibus blanditiis ipsa in, asperiores eos quam maxime commodi molestias facilis fugit illo cupiditate eveniet quidem culpa! Nisi, voluptatibus?",
    },
    {
      question: "How does billing work?",
      answer:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam harum autem temporibus blanditiis ipsa in, asperiores eos quam maxime commodi",
    },
    {
      question: "How do I change my account email?",
      answer:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam harum autem temporibus blanditiis ipsa in, asperiores eos quam maxime commodi molestias facilis fugit illo cupiditate eveniet quidem culpa! Nisi, voluptatibus?",
    },
  ];
  return (
    <>
      <div className="w-100 hero 2xl:h-[635px] xl:h-[560px] lg:h-[400px] md:h-[415px]">
        <div className="2xl:px-[80px] xl:px-[60px] md:px-[70px] lg:px-[50px] sm:px-[30px] px-[20px] pt-14 sm:pb-0 pb-0 p-[20px] grid grid-cols-12 items-center">
          <div className="col-span-12 md:col-span-6 pb-5">
            <h1 className="text-center md:text-left 2xl:text-[64px] xl:text-[45px] lg:text-[30px] md:text-[30px] sm:text-[60px] text-[30px] 2xl:leading-[80px] xl:leading-[60px] lg:leading-[40px] md:leading-[35px] sm:leading-[65px] lg:font-[600] md:font-[500] sm:font-[600]">
              <span className="text-white ">
                Boost Your Sales
                <br /> Locally with Renko:
                <br />
              </span>
              <span className="text-yellow-300"> Sell Fast, Earn More</span>
            </h1>
            <div className="flex items-center">
              <p className="pt-[24px] font-[500] 2xl:text-[24px] xl:text-[20px] lg:text-[16px] md:text-[15px] sm:text-[25px] 2xl:leading-9 xl:leading-7 lg:leading-6  md:leading-7 sm:leading-9 text-center md:text-left text-white">
                Be the first to set up your Renko store on the next-generation
                marketplace
              </p>
              <img
                src={arrow}
                className="2xl:w-56 xl:w-40 lg:w-36 lg:block hidden"
              />
            </div>
            <div className="flex gap-4 mt-8 md:justify-start justify-center">
              <a
                href="https://apps.apple.com/in/app/renko-partners/id1672284016"
                target="blank"
              >
                <img
                  src={appstore}
                  className="2xl:w-[198px] xl:w-[150px] lg:w-[120px] md:w-[120px] sm:w-[198px] w-[120px] 2xl:h-[63px] xl:h-[55px] lg:h-[45px] md:h-[35px] sm:h-[65px] "
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.colludprovider.app&pcampaignid=web_share"
                target="blank"
              >
                <img
                  src={playstore}
                  className="2xl:w-[198px] xl:w-[150px] lg:w-[125px] md:w-[120px] sm:w-[198px] w-[120px] 2xl:h-[63px] xl:h-[55px] lg:h-[45px] md:h-[35px] sm:h-[65px] "
                />
              </a>
            </div>
          </div>
          <div className="col-span-12 md:col-span-6 md:ms-[54px] flex justify-center md:justify-end">
            <img
              src={mainimg}
              className="2xl:h-[580px] xl:h-[505px] lg:h-[345px] md:h-[360px] sm:h-[550px] h-[365px] "
            />
          </div>
        </div>
      </div>

      <div className="2xl:px-[80px] xl:px-[60px] md:px-[70px] lg:px-[50px] sm:px-[30px] px-[20px] ">
        <div className="text-center py-5 ">
          <h1 className="font-[600] 2xl:text-4xl lg:text-3xl md:text-3xl text-2xl xl:my-10 lg:my-8 my-5">
            Not just another marketplace
          </h1>
          <div className="2xl:px-[380px] xl:px-72  lg:px-48 md:px-20">
            <p className="2xl:text-[18px] xl:text-sm 2xl:leading-[26px] pb-12">
              Expand your reach with Renko! Showcase your business on our
              platform to connect directly with local customers. Plus, explore
              our Jobs section for local employment opportunities, list or find
              properties, and use My Task for community-driven assistance
            </p>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-8 mt-8 hidden xl:flex justify-center">
          <div className="bg-[#FFC8C8] rounded-xl text-center pt-5 col-span-3 w-full">
            <div className="px-3 ">
              <h1 className="font-bold 2xl:text-4xl xl:text-3xl lg:text-2xl text-xl pb-3">
                Services
              </h1>
              <p className="font-semibold 2xl:text-[18px] xl:text-sm sm:text-xs 2xl:leading-[26px]">
                Upload unlimited products and <br /> services. Sell smarter with
                ease <br /> and efficiency.
              </p>
            </div>
            <div className="flex justify-center">
              <img src={services} className="pt-5 h-[409px] " />
            </div>
          </div>
          <div className="bg-[#FFED90] rounded-xl text-center pt-5 col-span-3 px-3 w-full">
            <h1 className="font-bold 2xl:text-4xl xl:text-3xl lg:text-2xl text-xl pb-3 ">
              Jobs
            </h1>
            <p className="font-semibold 2xl:text-[18px] xl:text-sm sm:text-xs 2xl:leading-[26px]">
              Reach a wider audience and <br /> find the perfect candidates for{" "}
              <br />
              your job listings
            </p>
            <div className="flex justify-center">
              <img src={jobs} className=" pt-5 h-[409px]" />
            </div>
          </div>
          <div className="bg-[#EDB6FF] rounded-xl text-center pt-5 col-span-3 px-3 w-full">
            <h1 className="font-bold 2xl:text-4xl xl:text-3xl lg:text-2xl text-xl pb-3 ">
              Property
            </h1>
            <p className="font-semibold 2xl:text-[18px] xl:text-sm sm:text-xs 2xl:leading-[26px]">
              Expand your visibility and
              <br /> connect with interested clients in <br /> your community
              effortlessly.
            </p>
            <div className="flex justify-center">
              <img src={properties} className="h-[409px]  pt-5" />
            </div>
          </div>
          <div className="bg-[#BAF9CF] rounded-xl text-center pt-5 col-span-3 w-full">
            <h1 className="font-bold 2xl:text-4xl xl:text-3xl lg:text-2xl text-xl pb-3 px-3">
              My Task
            </h1>
            <p className="font-semibold 2xl:text-[18px] xl:text-sm sm:text-xs 2xl:leading-[26px] px-3">
              Simplify your to-do list with <br />
              Renko: local experts help find <br />
              your nanny.
            </p>
            <div className="flex justify-center">
              <img src={task} className="h-[409px] pt-5" />
            </div>
          </div>
        </div>
        <div className="block xl:hidden slider-container mt-5 mb-10">
          <Slider {...settings}>
            <div className="pb-12">
              <div className="bg-[#FFC8C8] rounded-xl text-center py-5 mx-3">
                <h1 className="font-semibold text-2xl pb-3">Services</h1>
                <p className="lg:font-semibold font-normal 2xl:text-[18px] xl:text-sm 2xl:leading-[26px] px-2">
                  Upload unlimited products and services. Sell smarter with ease
                  and efficiency.
                </p>
              </div>
              <div className="flex justify-center mx-5">
                <img
                  src={services}
                  className="pt-5 2xl:h-[539px] md:h-[450px] lg:h-[500px] h-[300px]"
                />
              </div>
            </div>
            <div>
              <div className="bg-[#FFED90]  rounded-xl text-center py-5 mx-3 lg:ms-3 lg:me-4">
                <h1 className="font-semibold text-2xl pb-3 ">Jobs</h1>
                <p className="lg:font-semibold font-normal 2xl:text-[18px] xl:text-sm  2xl:leading-[26px]  px-2">
                  Reach a wider audience and find the perfect candidates for
                  your job listings
                </p>
              </div>
              <div className="flex justify-center mx-5">
                <img
                  src={jobs}
                  className=" pt-5 2xl:h-[539px] md:h-[450px] lg:h-[500px] h-[300px]"
                />
              </div>
            </div>
            <div>
              <div className="bg-[#EDB6FF] rounded-xl text-center py-5 ">
                <h1 className="font-semibold text-2xl pb-3">Property</h1>
                <p className="lg:font-semibold font-normal 2xl:text-[18px] xl:text-sm 2xl:leading-[26px] md:text-[15px] sm:text-[15px] sm:px-0 px-1">
                  Expand your visibility and connect with interested clients in
                  your community effortlessly.
                </p>
              </div>
              <div className="flex justify-center mx-5">
                <img
                  src={properties}
                  className=" pt-5 2xl:h-[539px] md:h-[450px] lg:h-[500px] h-[300px]"
                />
              </div>
            </div>
            <div>
              <div className="bg-[#BAF9CF] rounded-xl text-center py-5 mx-5">
                <h1 className="font-semibold text-2xl pb-3 px-2">My Task</h1>
                <p className="lg:font-semibold font-normal 2xl:text-[18px] xl:text-sm  2xl:leading-[26px]  px-3">
                  Simplify your to-do list with Renko: local experts help find
                  your nanny.
                </p>
              </div>
              <div className="flex justify-center mx-5">
                <img
                  src={task}
                  className=" pt-5 2xl:h-[539px] md:h-[450px] lg:h-[500px] h-[300px]"
                />
              </div>
            </div>
          </Slider>
        </div>
      </div>

      <div className="2xl:my-24 xl:my-20 lg:my-16 md:my-14 my-5 2xl:mx-[80px] xl:mx-[60px] md:mx-[70px] lg:mx-[50px] sm:mx-[30px] mx-[20px]">
        <div className="grid grid-cols-12 gap-5">
          <div className="col-span-12 md:col-span-6">
            <h1 className="font-semibold text-center md:text-start 2xl:text-[44px] xl:text-[36px] lg:text-[30px] md:text-[22px] sm:text-[30px] text-[20px] md:leading-[35px] 2xl:leading-[53px] xl:text-[40px] text-[28px] leading-[36px] xl:leading-[45px] pb-5">
              Grow Your Business in a
              <span className="text-fuchsia-600	"> Stress-Free</span> &
              <span className="text-fuchsia-600	"> Secure Way </span>
              with Renko
            </h1>

            <div className="md:my-10 my-5">
              <div className="flex gap-4 pb-6">
                <img
                  src={check}
                  className="md:h-8 md:w-8 sm:h-7 sm:w-7 h-6 w-6"
                ></img>
                <div>
                  <h1 className="font-semibold md:text-[15px] md:leading-[25px] text-[20px] leading-[30px]">
                    No-Code Store Setup:
                  </h1>
                  <p className="text-base text-[#4D4C54]">
                    Enables businesses to create their online presence quickly
                    and without technical skills.
                  </p>
                </div>
              </div>
              <div className="flex gap-4 pb-6">
                <img
                  src={check}
                  className="md:h-8 md:w-8 sm:h-7 sm:w-7 h-6 w-6"
                ></img>
                <div>
                  <h1 className="font-semibold md:text-[15px] md:leading-[25px] text-[20px] leading-[30px]">
                    Integrated Listings:
                  </h1>
                  <p className="text-base text-[#4D4C54]">
                    Offers job and property listings along with traditional
                    product and service sales.
                  </p>
                </div>
              </div>
              <div className="flex gap-4 pb-6">
                <img
                  src={check}
                  className="md:h-8 md:w-8 sm:h-7 sm:w-7 h-6 w-6"
                ></img>
                <div>
                  <h1 className="font-semibold md:text-[15px] md:leading-[25px] text-[20px] leading-[30px]">
                    Diverse Categories:
                  </h1>
                  <p className="text-base text-[#4D4C54]">
                    Supports a wide range of business types from services to
                    retail.
                  </p>
                </div>
              </div>
            </div>
            <button
              className="py-[13px] px-[20px]  bg-[#0F0E13] text-white rounded-xl"
              type="button"
            >
              Let's start today
            </button>
          </div>
          <div className="col-span-12 md:col-span-6 flex items-center 2xl:justify-center justify-center md:justify-end my-10 md:my-0">
            <img
              src={sec1}
              alt=""
              className="md:h-[300px] sm:h-[400px] h-[250px]"
            ></img>
          </div>
        </div>
      </div>
      {/* Our Key feature */}
      <div className="2xl:my-24 xl:my-20 lg:my-16 md:my-14 my-5 2xl:mx-[80px] xl:mx-[60px] md:mx-[70px] lg:mx-[50px] sm:mx-[30px] mx-[20px]">
        <div className="mb-10">
          <h1 className="font-semibold text-center 2xl:text-[44px] xl:text-[36px] lg:text-[30px] md:text-[22px] sm:text-[30px] text-[20px] md:leading-[35px] 2xl:leading-[53px] xl:text-[40px] text-[28px] leading-[36px] xl:leading-[45px] pb-5">
            Our Key feature
          </h1>
          <p className="text-base text-center">
            Equipped with all the essential tools to increase sales, manage your
            schedule, and keep clients returning, allowing you to concentrate on
            what you excel at.
          </p>
        </div>
        {/* Service setup */}

        <div className="grid grid-cols-12 gap-5">
          <div className="col-span-12 md:col-span-6 flex items-center">
            <div>
              <h1 className="font-semibold text-center md:text-start 2xl:text-[44px] xl:text-[36px] lg:text-[30px] md:text-[22px] sm:text-[30px] text-[20px] md:leading-[35px] 2xl:leading-[53px] xl:text-[40px] text-[28px] leading-[36px] xl:leading-[45px] pb-5">
                Service Setup
              </h1>

              <p className="text-base pb-8 md:text-start text-center">
                Easily set up your service details on Renko, including
                appointment scheduling, images, titles, descriptions, and
                specifics from global suppliers with just one click.
              </p>
              <div className="flex md:justify-start justify-center">
                <button
                  className="py-[13px] px-[20px]  bg-[#0F0E13] text-white rounded-xl "
                  type="button"
                >
                  Start selling
                </button>
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-6 flex justify-center 2xl:justify-center md:justify-end my-10 md:my-0">
            <img
              src={sec2}
              alt=""
              className="md:h-[300px] sm:h-[400px] h-[300px]"
            ></img>
          </div>
        </div>
      </div>
      {/* Product setup */}
      <div className="2xl:my-24 xl:my-20 lg:my-16 md:my-14 my-5 2xl:mx-[80px] xl:mx-[60px] md:mx-[70px] lg:mx-[50px] sm:mx-[30px] mx-[20px]">
        <div className="grid grid-cols-12 gap-5">
          <div className="col-span-12 md:col-span-6 flex items-center md:order-2">
            <div>
              <h1 className="font-semibold text-center md:text-start 2xl:text-[44px] xl:text-[36px] lg:text-[30px] md:text-[22px] sm:text-[30px] text-[20px] md:leading-[35px] 2xl:leading-[53px] xl:text-[40px] text-[28px] leading-[36px] xl:leading-[45px] pb-5">
                Product Setup
              </h1>

              <p className="text-base pb-8 md:text-start text-center">
                Set up your product listings quickly, streamline your management
                effortlessly, and boost your online store.
              </p>
              <div className="flex md:justify-start justify-center">
                <button
                  className="py-[13px] px-[20px]  bg-[#0F0E13] text-white rounded-xl"
                  type="button"
                >
                  Start selling
                </button>
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-6 flex justify-center 2xl:justify-center md:justify-start my-10 md:my-0">
            <img
              src={sec3}
              alt=""
              className="md:h-[300px] sm:h-[400px] h-[300px]"
            ></img>
          </div>
        </div>
      </div>
      {/* Discovery reels */}
      <div className="2xl:my-24 xl:my-20 lg:my-16 md:my-14 my-5 2xl:mx-[80px] xl:mx-[60px] md:mx-[70px] lg:mx-[50px] sm:mx-[30px] mx-[20px]">
        <div className="grid grid-cols-12 gap-5">
          <div className="col-span-12 md:col-span-6 flex items-center">
            <div>
              <h1 className="font-semibold text-center md:text-start 2xl:text-[44px] xl:text-[36px] lg:text-[30px] md:text-[22px] sm:text-[30px] text-[20px] md:leading-[35px] 2xl:leading-[53px] xl:text-[40px] text-[28px] leading-[36px] xl:leading-[45px] pb-5">
                Discovery Reels
              </h1>

              <p className="text-base pb-8 md:text-start text-center">
                Add story videos to the listing page for a dynamic and engaging
                visual experience, replacing static listing page images. This
                update brings listings to life, offering a more interactive and
                immersive way for users to view products and services.
              </p>
              <div className="flex md:justify-start justify-center">
                <button
                  className="py-[13px] px-[20px]  bg-[#0F0E13] text-white rounded-xl "
                  type="button"
                >
                  Start selling
                </button>
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-6 flex justify-center 2xl:justify-center md:justify-end my-10 md:my-0">
            <img
              src={sec4}
              alt=""
              className="md:h-[300px] sm:h-[400px] h-[300px]"
            ></img>
          </div>
        </div>
      </div>
      {/* Scheduling */}
      <div className="2xl:my-24 xl:my-20 lg:my-16 md:my-14 my-5 2xl:mx-[80px] xl:mx-[60px] md:mx-[70px] lg:mx-[50px] sm:mx-[30px] mx-[20px]">
        <div className="grid grid-cols-12 gap-5">
          <div className="col-span-12 md:col-span-6 flex items-center md:order-2">
            <div>
              <h1 className="font-semibold text-center md:text-start 2xl:text-[44px] xl:text-[36px] lg:text-[30px] md:text-[22px] sm:text-[30px] text-[20px] md:leading-[35px] 2xl:leading-[53px] xl:text-[40px] text-[28px] leading-[36px] xl:leading-[45px] pb-5">
                Scheduling
              </h1>

              <p className="text-base pb-8 md:text-start text-center">
                Manage your team's workload effortlessly with a user-friendly
                interface. Access a single view of all order flows on the go,
                allowing your team to focus on delivering exceptional service
                Add period
              </p>
              <div className="flex md:justify-start justify-center">
                <button
                  className="py-[13px] px-[20px]  bg-[#0F0E13] text-white rounded-xl"
                  type="button"
                >
                  Start selling
                </button>
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-6 flex justify-center 2xl:justify-center md:justify-start my-10 md:my-0">
            <img
              src={sec5}
              alt=""
              className="md:h-[300px] sm:h-[400px] h-[300px]"
            ></img>
          </div>
        </div>
      </div>
      {/* Payment Processing */}
      <div className="2xl:my-24 xl:my-20 lg:my-16 md:my-14 my-5 2xl:mx-[80px] xl:mx-[60px] md:mx-[70px] lg:mx-[50px] sm:mx-[30px] mx-[20px]">
        <div className="grid grid-cols-12 gap-5">
          <div className="col-span-12 md:col-span-6 flex items-center">
            <div>
              <h1 className="font-semibold text-center md:text-start 2xl:text-[44px] xl:text-[36px] lg:text-[30px] md:text-[22px] sm:text-[30px] text-[20px] md:leading-[35px] 2xl:leading-[53px] xl:text-[40px] text-[28px] leading-[36px] xl:leading-[45px] pb-5">
                Payment Processing
              </h1>

              <p className="text-base pb-8 md:text-start text-center">
                Provide clear, upfront quotes so clients know exactly what to
                expect—no surprises. Payments are only released once services
                are approved, ensuring your satisfaction.
              </p>
              <div className="flex md:justify-start justify-center">
                <button
                  className="py-[13px] px-[20px]  bg-[#0F0E13] text-white rounded-xl "
                  type="button"
                >
                  Start selling
                </button>
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-6 flex justify-center 2xl:justify-center md:justify-end my-10 md:my-0">
            <img
              src={sec6}
              alt=""
              className="md:h-[300px] sm:h-[400px] h-[300px]"
            ></img>
          </div>
        </div>
      </div>

      {/* Choose you preferred plan  */}
      {/* <div className="2xl:my-24 xl:my-20 lg:my-16 my-5 2xl:px-[80px] xl:px-[60px] md:px-[70px] lg:px-[50px] sm:px-[30px] px-[20px] bg-[#F6F6F4]">
        <div className="md:px-14 px-0">
          <h1 className="font-[600] text-center 2xl:text-[44px] xl:text-[36px] lg:text-[30px] md:text-[22px] sm:text-[30px] text-[20px] md:leading-[35px] 2xl:leading-[53px] xl:text-[40px] text-[28px] leading-[36px] xl:leading-[45px] py-10">
            Choose your preferred plan
          </h1>
          <div className="flex flex-col items-center">
            <nav className="flex overflow-x-auto items-center p-1 space-x-1 text-sm text-gray-600 bg-white rounded-xl text-center">
              <button
                role="tab"
                type="button"
                onClick={() => handleTabClick("Monthly")}
                className={`flex whitespace-nowrap justify-center items-center xl:text-2xl md:text-xl md:h-10 md:w-48 xl:h-12 xl:w-56 w-40 h-8 px-4 py-2.5 font-medium rounded-lg transition-all duration-300 ${
                  activeTab === "Monthly"
                    ? "bg-[#0F0E13] text-white"
                    : "bg-transparent text-gray-600"
                }`}
              >
                Monthly
              </button>

              <button
                role="tab"
                type="button"
                onClick={() => handleTabClick("Annual")}
                className={`flex whitespace-nowrap justify-center items-center xl:text-2xl md:text-xl xl:h-12 xl:w-56 md:w-48 md:h-10 h-8 w-40 px-4 py-2.5  font-medium rounded-lg transition-all duration-300 ${
                  activeTab === "Annual"
                    ? "bg-[#0F0E13] text-white"
                    : "bg-transparent text-gray-600"
                }`}
              >
                Annual (Save 20%)
              </button>
            </nav>

            <div id="tab-content" className="my-10">
              <div
                id="content-Monthly"
                className={`tab-content ${
                  activeTab === "Monthly" ? "block" : "hidden"
                }`}
              >
                <div className="bg-white rounded-xl border-2 divide-y-2">
                  <div className="pb-3 px-8 py-6 ">
                    <div className="flex justify-between">
                      <h1 className="xl:text-4xl md:text-3xl lg:text-3xl sm:text-2xl text-xl md:text-2xl font-[600]">
                        Monthly
                      </h1>
                      <h1 className="text-[#1AA84C] text-4xl md:text-3xl font-[600]">
                        $34.99
                      </h1>
                    </div>{" "}
                    <p className="text-[#4D4C54] xl:text-lg pt-3">
                      Pay monthly with regular price
                    </p>
                  </div>
                  <div className="pt-5 px-8 py-6 ">
                    <h1 className="font-[500] text-2xl pb-5">What’s inside?</h1>
                    <ul className="list-disc list-inside text-base pb-5">
                      <li>Set up a limited product and services</li>
                      <li>Direct instant messaging with Customer</li>
                      <li>Unlimited video post</li>
                      <li>Unlimited customer notification messages</li>
                      <li>Store, QR code optimisation</li>
                      <li>Payment processing</li>
                      <li>Appointment scheduling</li>
                      <li>Reporting and analytics</li>
                      <li>Marketing promotions</li>
                    </ul>
                    <button
                      type="button"
                      className="pb-5 bg-black text-white rounded-xl flex justify-center text-base px[20px] py-[13px] w-full font-[600]"
                    >
                      Let's start today!
                    </button>
                  </div>
                </div>
              </div>
              <div
                id="content-Annual"
                className={`tab-content ${
                  activeTab === "Annual" ? "block" : "hidden"
                }`}
              >
                <div className="bg-white rounded-xl border-2 divide-y-2">
                  <div className="pb-3 px-8 py-6 ">
                    <div className="flex justify-between ">
                      <h1 className="xl:text-4xl md:text-3xl lg:text-3xl sm:text-2xl text-xl md:text-2xl  font-[600]">
                        Yearly
                      </h1>
                      <div className="flex">
                        <h1 className="text-[#BABABA] text-4xl md:text-3xl font-[600]">
                          $419.88
                        </h1>
                        <h1 className="text-[#1AA84C] text-4xl md:text-3xl font-[600]">
                          $335.92
                        </h1>
                      </div>
                    </div>
                    <div className="flex justify-between  pt-3">
                      <p className="text-[#4D4C54] text-lg">
                        Pay yearly and save
                        <span className="text-[#1AA84C]">$83.96</span>
                      </p>
                      <img
                        src={save}
                        className="w-24 h-6 flex items-center justify-center"
                      ></img>
                    </div>
                  </div>
                  <div className="pt-5 px-8 py-6 ">
                    <h1 className="font-[500] text-2xl pb-5">What’s inside?</h1>
                    <ul className="list-disc list-inside text-base pb-5">
                      <li>Set up a limited product and services</li>
                      <li>Direct instant messaging with Customer</li>
                      <li>Unlimited video post</li>
                      <li>Unlimited customer notification messages</li>
                      <li>Store, QR code optimisation</li>
                      <li>Payment processing</li>
                      <li>Appointment scheduling</li>
                      <li>Reporting and analytics</li>
                      <li>Marketing promotions</li>
                    </ul>
                    <button
                      type="button"
                      className="pb-5 bg-black text-white rounded-xl flex justify-center text-base px[20px] py-[13px] w-full font-[600]"
                    >
                      Let's start today!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-12 md:gap-x-10 gap-y-10">
            <div className="col-span-12 md:col-span-6 rounded-xl bg-white p-4">
              <h1 className="xl:text-4xl md:text-3xl lg:text-3xl sm:text-2xl text-xl md:text-2xl font-semibold pb-2">
                Additional Staff Members
              </h1>
              <p className="text-base pb-2">
                Add staffers if you have a bigger team. Additional cost only up
                to the first 9 staffers, for more than 9 there is no additional
                cost.
              </p>
              <p className="text-[#4C4C4C] xl:text-[36px] xl:leading-[45px] font-semibold pb-2">
                $10.00
              </p>
            </div>
            <div className="col-span-12 md:col-span-6 rounded-xl bg-white">
              {" "}
              <div className="col-span-6 rounded-xl bg-white  p-4">
                <h1 className="xl:text-4xl md:text-3xl lg:text-3xl sm:text-2xl text-xl md:text-2xl font-semibold pb-2">
                  Local Leads{" "}
                </h1>
                <p className="text-base pb-2">
                  Win more jobs in your area with unlimitedly local leads.
                </p>
                <p className="text-[#4C4C4C] xl:text-[36px] xl:leading-[45px] font-semibold pb-2">
                  $15.00{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="2xl:my-24 xl:my-20 lg:my-16 md:my-14 my-5 2xl:px-[80px] xl:px-[60px] md:px-[70px] lg:px-[20px] sm:px-0 px-0">
        <div className="w-100 hero md:rounded-xl rounded-3xl 2xl:h-[635px] xl:h-[500px] lg:h-[400px] md:h-[275px]">
          <div className="2xl:px-[80px] xl:px-[60px] md:px-[70px] lg:px-[50px] sm:px-[30px] px-[20px] pt-14 sm:pb-0 pb-0 p-[20px] grid grid-cols-12 items-center">
            <div className="col-span-12 md:col-span-6 pb-5">
              <h1 className="text-center text-white md:text-left 2xl:text-[64px] xl:text-[60px] lg:text-[40px] md:text-[25px] text-[36px] text-[30px] 2xl:leading-[80px] xl:leading-[72px] lg:leading-[50px] md:leading-[35px] leading-[45px] lg:font-[600] md:font-[500] sm:font-[600]">
                Get Discovered Locally – Sell with Renko Now
              </h1>

              <div className="flex gap-4 mt-8 md:justify-start justify-center">
                <a
                  href="https://apps.apple.com/in/app/renko-partners/id1672284016"
                  target="blank"
                >
                  <img
                    src={appstore}
                    className="2xl:w-[198px] xl:w-[150px] lg:w-[145px] md:w-[120px] sm:w-[198px] w-[120px] 2xl:h-[63px] xl:h-[55px] lg:h-[45px] md:h-[35px] sm:h-[65px] "
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.colludprovider.app&pcampaignid=web_share"
                  target="blank"
                >
                  <img
                    src={playstore}
                    className="2xl:w-[198px] xl:w-[150px] lg:w-[140px] md:w-[120px] sm:w-[198px] w-[120px] 2xl:h-[63px] xl:h-[55px] lg:h-[45px] md:h-[35px] sm:h-[65px] "
                  />
                </a>
              </div>
            </div>
            <div className="col-span-12 md:col-span-6 md:ms-[54px] flex justify-center md:justify-end">
              <img
                src={sec7}
                className="2xl:h-[580px] xl:h-[445px] lg:h-[345px] md:h-[220px] sm:h-[550px] h-[365px] "
              />
            </div>
          </div>
        </div>
      </div>

      <div className="2xl:my-24 xl:my-20 lg:my-16 md:my-14 my-10 2xl:mx-[80px] xl:mx-[60px] md:mx-[70px] lg:mx-[50px] sm:mx-[30px] mx-[20px]">
        <div className="grid grid-cols-12 gap-5">
          <div className="col-span-12 md:col-span-6">
            <h1 className="text-center md:text-start xl:text-[40px] md:text-[30px] text-[30px] xl:leading-[48px] md:leading-[38px] leading-[32px] font-semibold pb-6 pt-5">
              FAQs
            </h1>
            <p className="text-center md:text-start text-[#475467] xl:text-lg md:text-sm text-sm pb-10">
              Want to know more? You can email us anytime at support@renko.app
            </p>
            <div className="flex justify-start  items-center pb-3">
              <button
                className="py-[13px] px-[20px] bg-[#0F0E13] text-white rounded-xl flex items-center"
                type="button"
              >
                Let's start today
              </button>
            </div>
            <p className="text-start font-[500] xl:text-base text-sm">
              Sign up to win a month FREE
            </p>
          </div>
          <div className="col-span-12 md:col-span-6">
            {faqData.map((faq, index) => (
              <div className="p-4 border-2 rounded-xl my-5" key={index}>
                <Disclosure as="div" open={openIndex === index}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        className="group flex w-full items-center justify-between"
                        onClick={() => handleToggle(index)}
                      >
                        <span className="text-lg text-start font-medium text-black group-hover:text-black/80">
                          {faq.question}
                        </span>
                        <span className="text-2xl text-[#00987C]">
                          {open ? (
                            <img src={minus} className="w-5" alt="minus" />
                          ) : (
                            <img src={plus} className="w-5" alt="plus" />
                          )}
                        </span>
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 text-base text-gray-500">
                        {faq.answer}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
